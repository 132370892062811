export const environment = {
    production: false,
    stripe_token: 'STRIPE_TOKEN',
    paypal_token: 'PAYPAL_TOKEN',
    loadGif: 'assets/images/loader.gif',
    currency: '',

    // PARAMETROS WHISKEY WINGS
    firebase: {
        apiKey: "AIzaSyDU2tigSQjxuX-BLPyKZuTix2-TUD0yEkA",
        authDomain: "ianos-app-whiskeywings-pro.firebaseapp.com",
        projectId: "ianos-app-whiskeywings-pro",
        storageBucket: "ianos-app-whiskeywings-pro.appspot.com",
        messagingSenderId: "527005496511",
        appId: "1:527005496511:web:17e739974341744406020a",
        measurementId: "G-6VKMFLEJFF"
    },
    googleMapsKey: 'AIzaSyCnyj164hLm7drlr85gbQ8tAIQD_ci8KxE',
    urlBase: 'https://us-central1-ianos-app-whiskeywings-pro.cloudfunctions.net',
    apikey: 'c6759ff8163-5dcedd57a0ecb-42b6-eb1280f5',
    urlBaseWompy: 'https://us-central1-ianos-app-whiskeywings-pro.cloudfunctions.net/Wompi',
    
    // TWILIO CREDENCIALES IanOS DEV
    twilio: {
        TWILIO_SERVICE_ENDPOINT: 'https://verify.twilio.com/v2/Services/VA6f37e2f3e25ae98433a02926ec75c77a/Verifications',
        TWILIO_SERVICE_VERIFICATION_CHECK: `https://verify.twilio.com/v2/Services/VA6f37e2f3e25ae98433a02926ec75c77a/VerificationCheck`,
        TWILIO_AUTH_TOKEN: '13b6af0768f7255a066f3436df02f6ca',
        TWILIO_ACCOUNT_SID: 'ACb5f6530b25d1d3d182d2df34d1747117',
        TWILIO_SERVICE_TOKEN: 'VA6f37e2f3e25ae98433a02926ec75c77a',
        TWILIO_MESSAGING_SERVICE_SID: 'MG7302002067524cd572d66063ff774b61'
    },

    // CREDENCIALES DE PRO ITWC
    wompi: {
        grant_type: 'client_credentials', // Dejar siempre client_credentials.
        audience: 'wompi_api', // Dejar siempre "wompi_api"
        client_id: 'dc4c16b2-08d9-4478-9037-40a580004f6b', // El client id del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
        client_secret: '2c1c79a5-8315-4989-8ade-1469183c0810', //Es la llave secreta del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
    },

    mailchimp: {
        apiKey: '28100cb5222079f4e991ec8a5dd7789a-us17',
        server: 'us17',
        listUniqueId: 'a10147ff75'
    }
};

